import { window } from "global";
import React, { useState, useEffect } from "react";
import classnames from "classnames";
import UAParser from "ua-parser-js";
import { useQueryString } from "@ugg/shared/hooks/use-query-string";
import { NewAppDownloadButton } from "components/Buttons/AppDownloadButton";
import AppFeature, { featuresList, featureFootnote } from "@ugg/shared/components/common/AppFeature";
import { appFilename } from "lib/file-download";
import { ReactComponent as LolLogo } from "@shared/assets/games/lol.svg";
import { ReactComponent as LolGreyLogo } from "@shared/assets/games/lol-grey.svg";
import { ReactComponent as ValorantLogo } from "@shared/assets/games/valorant.svg";
import { ReactComponent as ValorantGreyLogo } from "@shared/assets/games/valorant-grey.svg";
import { Helmet } from "react-helmet-async";

const parser = new UAParser();
const os = parser.getOS();
const isMac = !!os?.name?.match(/mac/gi);

function toHex(str) {
  let h = "";
  for (let i = 0; i < str.length; i++) {
    h += str.charCodeAt(i).toString(16);
  }
  return h;
}

const appTabs = [
  {
    game: "lol",
    label: "League of Legends",
    Icon: LolLogo,
    IconGrey: LolGreyLogo,
    link: "/app",
    isActive: true,
  },
  {
    game: "valorant",
    label: "Valorant",
    Icon: ValorantLogo,
    IconGrey: ValorantGreyLogo,
    link: "https://u.gg/val/app?utm_source=UGG&utm_campaign=LolAppPage",
    beta: true,
    isActive: false,
  },
];

export default function DesktopAppSplashPage() {
  const queryString = useQueryString();

  useEffect(() => {
    window?.scrollTo(0, 0);
    // Remove skin ads
    try {
      window.top.document.getElementById("siteSkinContainer").remove();
    } catch (e) {}
  }, []);

  return (
    <div className="desktop-app-splash-page">
      <Helmet>
        <title>U.GG App</title>
      </Helmet>
      <div className="desktop-app-splash-page_width-container">
        <div className="video-bg">
          <div className="overlay" />
          <video playsInline autoPlay loop muted>
            <source type="video/webm" src="https://static.bigbrain.gg/assets/ugg/pages/desktop-app/ugg-standalone-app.webm" />
          </video>
        </div>
        <div className="app-tabs flex items-center gap-[12px] mt-[18px]">
          {appTabs.map((tab) => {
            return <GameAppTab key={tab.game} {...tab} />;
          })}
        </div>
        <div className="desktop-app-splash-page_header">
          <h1>
            <div>
              <span>Take the guesswork out of league</span>
            </div>
            <div>
              <strong>and focus on winning</strong>
            </div>
          </h1>
          <h2>
            The U.GG app is available only on <strong style={{ color: "#ffffff" }}>Windows</strong>.
          </h2>
          {/* <h2>
            The U.GG app is now available on both <br /> <strong style={{ color: "#ffffff" }}>Windows</strong> and{" "}
            <strong style={{ color: "#ffffff" }}>macOS</strong>.
          </h2> */}
          <div className="download-row">
            {window && (
              <>
                <NewAppDownloadButton
                  url="https://u.gg/get-windows-app"
                  filename={appFilename(toHex((queryString.utm_campaign || "").toLowerCase()))}
                  label="Windows"
                  icon="windows"
                />
                {/* <AppDownloadButton label="macOS" url="https://u.gg/get-mac-app" icon="apple" dim={!isMac} download href /> */}
              </>
            )}
          </div>
          <a className="block mt-[24px] text-[14px]" href="https://u.gg/get-windows-app" download>
            Download button not working? <span className="text-accent-blue-400 underline">Click here.</span>
          </a>
          <div style={{ marginTop: 24 }}>
            <span style={{ fontSize: 14, color: "#CDDCFE", textDecoration: "underline" }}>Riot Games Compliant</span>
          </div>
        </div>
        <div className="desktop-app-splash-page_features-container">
          <div className="features-container_header">
            <h1>Features</h1>
          </div>
          <div className="features-grid">
            {featuresList.map((feature, index) => {
              return <AppFeature key={index} {...feature} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

function GameAppTab(props) {
  const { label, Icon, IconGrey, link, beta, isActive } = props;
  const [hover, setHover] = useState(false);

  return (
    <a
      className={classnames("flex items-center justify-center px-[12px] py-[10px] rounded-[3px] bg-purple-400 font-['Inter']", {
        "!bg-purple-100": hover,
        "!bg-purple-100 border-t-[1px] border-[#575792] pointer-events-none": isActive,
      })}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      href={link}
    >
      {isActive || hover ? <Icon className="w-[18px]" /> : <IconGrey className="w-[18px]" />}
      <div
        className={classnames("ml-[8px] text-[13px] text-lavender-50", { "text-white font-bold": isActive, "text-white": hover })}
      >
        {label}
      </div>
      {beta && (
        <div className="flex items-center ml-[8px] px-[4px] h-[14px] rounded-[1px] bg-accent-forest-green-500 font-bold text-[10px]">
          BETA
        </div>
      )}
    </a>
  );
}
